<template>
  <div>
    <TitleMore
      :title="['应急救护小知识']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard pageCenter">
      <div style="margin-bottom:20px">
        <el-carousel
          indicator-position="inside"
          style="height:100%;cursor:pointer"
        >
          <el-carousel-item
            v-for="(item,index) in ListDataBanner"
            :key="index"
          >
            <img
              @click="$goto('/detailShow',{id:item.id})"
              style="width:100%;height:100%;cursor:pointer"
              :src="item.smallImage + '!1160x600'"
              alt=""
            >
          </el-carousel-item>
        </el-carousel>
      </div>
      <div
        class="itemText"
		style="margin-bottom: 10px;"
        v-for="(item,index) in ListData"
        :key="index"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../assets/img/rescue.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
<!--        <p class="itemTextTime">{{item.publishDate}}</p>-->
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        :total="total"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      ListDataBanner: [],
      ListData: [],
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  created() {
    this.getList();
  },

  methods: {
    // handleSizeChange(val) {
    // },
    handleCurrentChange(val) {
      this.page=val
      this.getList()
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "yjjhxzs", page: this.page-1, size: this.size })
        .then((res) => {
			let _this = this;
			res.results.data.forEach((item,ind)=>{
				console.log(item.smallImage)
			  if(_this.ListDataBanner.length<5 && item.smallImage){
			    this.ListDataBanner.push(item)
			  }
			})
			//this.ListDataBanner = res.results.data.slice(0, 5);
			this.ListData = res.results.data;
			this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>
<style lang="less" scoped>
	.itemTextContent {
		&:hover {
			color: #d1292e;
		}
	}
  /deep/.el-carousel__container{
    height: 600px;
  }
</style>
